import { motion, useInView } from "framer-motion";
import React, { useRef } from "react";
import { SlideUp } from "../utility/animation";

export function CertificateCard({props}) {

    const ref = useRef(null);
    const isInView = useInView(ref, { once: true });

    return (
        <motion.div 
            className="flex flex-col p-10 bg-white items-left rounded-xl lg:p-4 hover-none"
            variants={SlideUp(0.4)}
            ref={ref}
            initial="hidden"
            animate={isInView ? "visible" : {}}
            whileHover={{ scale: 1.05 }}
            transition={{ type: "tween", stiffness: 300 }}
        >
            <img src={props.image} className="object-cover border border-gray-200 rounded-lg h-[55%]"></img>
            <div className="flex flex-col flex-grow h-full">
                <h3 className="mt-8 ml-1 text-3xl font-semibold text-left text-gray-700 lg:text-sm lg:mt-2">{props.title}</h3>
                <div className="flex items-center gap-2 my-4">
                    <img src={props.logo1} className="object-contain h-12 lg:h-4"></img>
                    <p className="text-lg text-left text-gray-500 lg:text-xs">
                        {props.logoName}
                    </p>
                </div>
                <p className="mt-auto mb-2 text-xl text-gray-500 lg:text-xs lg:mb-0">
                    {props.details}
                </p>
            </div>
        </motion.div>
    )
}

export default CertificateCard;